.accordionjs {
  position: relative;
  margin: 0;
  padding: 0;
  list-style: none;
  margin-top: 10px;
  margin-bottom: 20px;

  .acc_section {
    border: $border;
    position: relative;
    margin-top: -1px;
    overflow: hidden;

    .acc_head {
      position: relative;
      background: #ebeff8;
      padding: 10px;
      display: block;
      cursor: pointer;
      border-radius: 4px;

      h3 {
        line-height: 1;
        margin: 5px;
        font-size: 16px;
        padding-left: 30px;
      }
    }

    .acc_content {
      padding: 10px;
    }

    &:first-of-type {
      border-top-left-radius: 3px;
      border-top-right-radius: 3px;

      .acc_head {
        border-top-left-radius: 3px;
        border-top-right-radius: 3px;
      }
    }

    &:last-of-type {
      border-bottom-left-radius: 3px;
      border-bottom-right-radius: 3px;

      .acc_content {
        border-bottom-left-radius: 3px;
        border-bottom-right-radius: 3px;
      }
    }

    &.acc_active {
      > {
        .acc_content {
          display: block;
        }

        .acc_head {
          background: #17B794;
          border-bottom: 1px solid #eee;
          color: $white;
        }
      }

      .acc_head h3:before {
        content: "\e995";
        position: absolute;
        font-family: 'feather' !important;
        left: 17px;
        color: $white;
        top: 12px;
        font-size: 20px;
        transition: all 0.5s;
        transform: scale(1);
      }
    }

    .acc_head h3:before {
      content: "\e9b0";
      position: absolute;
      font-family: 'feather' !important;
      left: 17px;
      top: 12px;
      font-size: 20px;
      transition: all 0.5s;
      transform: scale(1);
    }
  }
}