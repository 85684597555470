

.select2-container {
  box-sizing: border-box;
  display: inline-block;
  margin: 0;
  position: relative;
  vertical-align: middle;

  .select2-selection--single {
    box-sizing: border-box;
    cursor: pointer;
    display: block;
    height: 28px;
    user-select: none;
    -webkit-user-select: none;

    .select2-selection__rendered {
      display: block;
      padding-left: 8px;
      padding-right: 20px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .select2-selection__clear {
      background-color: transparent;
      border: none;
      font-size: 1em;
    }
  }

  &[dir="rtl"] .select2-selection--single .select2-selection__rendered {
    padding-right: 8px;
    padding-left: 20px;
  }

  .select2-selection--multiple {
    box-sizing: border-box;
    cursor: pointer;
    display: block;
    min-height: 32px;
    user-select: none;
    -webkit-user-select: none;

    .select2-selection__rendered {
      display: inline;
      list-style: none;
      padding: 0;
    }

    .select2-selection__clear {
      background-color: transparent;
      border: none;
      font-size: 1em;
    }
  }

  .select2-search--inline .select2-search__field {
    box-sizing: border-box;
    border: none;
    font-size: 100%;
    margin-top: 5px;
    margin-left: 5px;
    padding: 0;
    max-width: 100%;
    resize: none;
    height: 18px;
    vertical-align: bottom;
    font-family: sans-serif;
    overflow: hidden;
    word-break: keep-all;

    &::-webkit-search-cancel-button {
      -webkit-appearance: none;
    }
  }
}

.select2-dropdown {
  background-color: white;
  border: $border;
  border-radius: 4px;
  box-sizing: border-box;
  display: block;
  position: absolute;
  left: -100000px;
  width: 100%;
  z-index: 10;
  box-shadow: 0px 16px 18px rgb(6, 10, 48, 0.1);
}

.select2-results {
  display: block;
}

.select2-results__options {
  list-style: none;
  margin: 0;
  padding: 0;
}

.select2-results__option {
  padding: 0.5rem;
  user-select: none;
  -webkit-user-select: none;
  padding-left: 15px;
}

.select2-results__option--selectable {
  cursor: pointer;
}

.select2-container--open {
  .select2-dropdown {
    left: 0;
  }

  .select2-dropdown--above {
    border-bottom: none;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .select2-dropdown--below {
    border-top: none;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}

.select2-search--dropdown {
  display: block;
  padding: 4px;

  .select2-search__field {
    padding: 4px;
    width: 100%;
    box-sizing: border-box;

    &::-webkit-search-cancel-button {
      -webkit-appearance: none;
    }
  }

  &.select2-search--hide {
    display: none;
  }
}

.select2-close-mask {
  border: 0;
  margin: 0;
  padding: 0;
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  min-height: 100%;
  min-width: 100%;
  height: auto;
  width: auto;
  opacity: 0;
  z-index: 99;
  background-color: $white;
  filter: alpha(opacity = 0);
}

.select2-hidden-accessible {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  height: 1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
  white-space: nowrap !important;
}

.select2-container--default {
  .select2-selection--single {
    background-color: $white;
    border: $border;
    border-radius: 4px;

    .select2-selection__rendered {
      color: #313e6a;
      line-height: 28px;
    }

    .select2-selection__clear {
      cursor: pointer;
      float: right;
      font-weight: bold;
      height: 26px;
      margin-right: 20px;
      padding-right: 0px;
    }

    .select2-selection__placeholder {
      color: #bcc0e2;
    }

    .select2-selection__arrow {
      height: 26px;
      position: absolute;
      top: -1px;
      right: 5px;
      width: 20px;

      b {
        border-color: #7f88a5 transparent transparent transparent;
        border-style: solid;
        border-width: 5px 4px 0 4px;
        height: 0;
        left: 50%;
        margin-left: -4px;
        margin-top: -2px;
        position: absolute;
        top: 50%;
        width: 0;
      }
    }
  }

  &[dir="rtl"] .select2-selection--single {
    .select2-selection__clear {
      float: left;
    }

    .select2-selection__arrow {
      left: 1px;
      right: auto;
    }
  }

  &.select2-container--disabled .select2-selection--single {
    background-color: #f2f6f9;
    cursor: default;

    .select2-selection__clear {
      display: none;
    }
  }

  &.select2-container--open .select2-selection--single .select2-selection__arrow b {
    border-color: transparent transparent #888 transparent;
    border-width: 0 4px 5px 4px;
  }

  .select2-selection--multiple {
    background-color: white;
    border: $border;
    border-radius: 4px;
    cursor: text;
    padding-bottom: 5px;
    padding-right: 5px;
    position: relative;

    &.select2-selection--clearable {
      padding-right: 25px;
    }

    .select2-selection__clear {
      cursor: pointer;
      font-weight: bold;
      height: 20px;
      margin-right: 10px;
      margin-top: 5px;
      position: absolute;
      right: 0;
      padding: 1px;
    }

    .select2-selection__choice {
      background-color: #e4e4e4;
      border: $border;
      border-radius: 4px;
      box-sizing: border-box;
      display: inline-block;
      margin-left: 5px;
      margin-top: 5px;
      padding: 0;
      padding-left: 20px;
      position: relative;
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      vertical-align: bottom;
      white-space: nowrap;
    }

    .select2-selection__choice__display {
      cursor: default;
      padding-left: 2px;
      padding-right: 5px;
      font-size: 13px;
    }

    .select2-selection__choice__remove {
      background-color: transparent;
      border: none;
      border-right: $border;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
      color: #7f88a5;
      cursor: pointer;
      font-size: 1em;
      font-weight: bold;
      padding: 0 4px;
      position: absolute;
      left: 0;
      top: 0;

      &:hover, &:focus {
        background-color: #f1f1f1;
        color: #333;
        outline: none;
      }
    }
  }

  &[dir="rtl"] .select2-selection--multiple {
    .select2-selection__choice {
      margin-left: 5px;
      margin-right: auto;
    }

    .select2-selection__choice__display {
      padding-left: 5px;
      padding-right: 2px;
    }

    .select2-selection__choice__remove {
      border-left: $border;
      border-right: none;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }

    .select2-selection__clear {
      float: left;
      margin-left: 10px;
      margin-right: auto;
    }
  }

  &.select2-container--focus .select2-selection--multiple {
    border: solid black 1px;
    outline: 0;
  }

  &.select2-container--disabled {
    .select2-selection--multiple {
      background-color: #eee;
      cursor: default;
    }

    .select2-selection__choice__remove {
      display: none;
    }
  }

  &.select2-container--open {
    &.select2-container--above {
      .select2-selection--single, .select2-selection--multiple {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
      }
    }

    &.select2-container--below {
      .select2-selection--single, .select2-selection--multiple {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
  }

  .select2-search--dropdown .select2-search__field {
    border: $border;
  }

  .select2-search--inline .select2-search__field {
    background: transparent;
    border: none;
    outline: 0;
    box-shadow: none;
    -webkit-appearance: textfield;
  }

  .select2-results > .select2-results__options {
    max-height: 200px;
    overflow-y: auto;
  }

  .select2-results__option .select2-results__option {
    padding-left: 1em;

    .select2-results__group {
      padding-left: 0;
    }

    .select2-results__option {
      margin-left: -1em;
      padding-left: 2em;

      .select2-results__option {
        margin-left: -2em;
        padding-left: 3em;

        .select2-results__option {
          margin-left: -3em;
          padding-left: 4em;

          .select2-results__option {
            margin-left: -4em;
            padding-left: 5em;

            .select2-results__option {
              margin-left: -5em;
              padding-left: 6em;
            }
          }
        }
      }
    }
  }

  .select2-results__option--group {
    padding: 0;
  }

  .select2-results__option--disabled {
    color: #7f88a5;
  }

  .select2-results__option--selected {
    background-color: #f4f5fb;
  }

  .select2-results__option--highlighted.select2-results__option--selectable {
    background-color: $white;
    color: $primary;
  }

  .select2-results__group {
    cursor: default;
    display: block;
    padding: 6px;
  }
}

.select2-container--classic {
  .select2-selection--single {
    background-color: #f7f7f7;
    border: $border;
    border-radius: 4px;
    outline: 0;
    background-image: -webkit-linear-gradient(top, $white 50%, #eee 100%);
    background-image: -o-linear-gradient(top, $white 50%, #eee 100%);
    background-image: linear-gradient(to bottom, $white 50%, #eee 100%);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='$whiteFFFFF', endColorstr='#FFEEEEEE', GradientType=0);

    &:focus {
      border: 1px solid $primary;
    }

    .select2-selection__rendered {
      color: #444;
      line-height: 28px;
    }

    .select2-selection__clear {
      cursor: pointer;
      float: right;
      font-weight: bold;
      height: 26px;
      margin-right: 20px;
    }

    .select2-selection__placeholder {
      color: #7f88a5;
    }

    .select2-selection__arrow {
      background-color: #f4f5fb;
      border: none;
      border-left: $border;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      height: 26px;
      position: absolute;
      top: 1px;
      right: 1px;
      width: 20px;
      background-image: -webkit-linear-gradient(top, #eee 50%, #ccc 100%);
      background-image: -o-linear-gradient(top, #eee 50%, #ccc 100%);
      background-image: linear-gradient(to bottom, #eee 50%, #ccc 100%);
      background-repeat: repeat-x;
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFEEEEEE', endColorstr='#FFCCCCCC', GradientType=0);

      b {
        border-color: #888 transparent transparent transparent;
        border-style: solid;
        border-width: 5px 4px 0 4px;
        height: 0;
        left: 50%;
        margin-left: -4px;
        margin-top: -2px;
        position: absolute;
        top: 50%;
        width: 0;
      }
    }
  }

  &[dir="rtl"] .select2-selection--single {
    .select2-selection__clear {
      float: left;
    }

    .select2-selection__arrow {
      border: none;
      border-right: $border;
      border-radius: 0;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
      left: 1px;
      right: auto;
    }
  }

  &.select2-container--open {
    .select2-selection--single {
      border: 1px solid $primary;

      .select2-selection__arrow {
        background: transparent;
        border: none;

        b {
          border-color: transparent transparent #888 transparent;
          border-width: 0 4px 5px 4px;
        }
      }
    }

    &.select2-container--above .select2-selection--single {
      border-top: none;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      background-image: -webkit-linear-gradient(top, $white 0%, #eee 50%);
      background-image: -o-linear-gradient(top, $white 0%, #eee 50%);
      background-image: linear-gradient(to bottom, $white 0%, #eee 50%);
      background-repeat: repeat-x;
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='$whiteFFFFF', endColorstr='#FFEEEEEE', GradientType=0);
    }

    &.select2-container--below .select2-selection--single {
      border-bottom: none;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      background-image: -webkit-linear-gradient(top, #eee 50%, $white 100%);
      background-image: -o-linear-gradient(top, #eee 50%, $white 100%);
      background-image: linear-gradient(to bottom, #eee 50%, $white 100%);
      background-repeat: repeat-x;
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFEEEEEE', endColorstr='$whiteFFFFF', GradientType=0);
    }
  }

  .select2-selection--multiple {
    background-color: white;
    border: $border;
    border-radius: 4px;
    cursor: text;
    outline: 0;
    padding-bottom: 5px;
    padding-right: 5px;

    &:focus {
      border: 1px solid $primary;
    }

    .select2-selection__clear {
      display: none;
    }

    .select2-selection__choice {
      background-color: #e4e4e4;
      border: $border;
      border-radius: 4px;
      display: inline-block;
      margin-left: 5px;
      margin-top: 5px;
      padding: 0;
    }

    .select2-selection__choice__display {
      cursor: default;
      padding-left: 2px;
      padding-right: 5px;
    }

    .select2-selection__choice__remove {
      background-color: transparent;
      border: none;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
      color: #888;
      cursor: pointer;
      font-size: 1em;
      font-weight: bold;
      padding: 0 4px;

      &:hover {
        color: #555;
        outline: none;
      }
    }
  }

  &[dir="rtl"] .select2-selection--multiple {
    .select2-selection__choice {
      margin-left: 5px;
      margin-right: auto;
    }

    .select2-selection__choice__display {
      padding-left: 5px;
      padding-right: 2px;
    }

    .select2-selection__choice__remove {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }

  &.select2-container--open {
    .select2-selection--multiple {
      border: 1px solid $primary;
    }

    &.select2-container--above .select2-selection--multiple {
      border-top: none;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }

    &.select2-container--below .select2-selection--multiple {
      border-bottom: none;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  .select2-search--dropdown .select2-search__field {
    border: $border;
    outline: 0;
  }

  .select2-search--inline .select2-search__field {
    outline: 0;
    box-shadow: none;
  }

  .select2-dropdown {
    background-color: $white;
    border: 1px solid transparent;
  }

  .select2-dropdown--above {
    border-bottom: none;
  }

  .select2-dropdown--below {
    border-top: none;
  }

  .select2-results > .select2-results__options {
    max-height: 200px;
    overflow-y: auto;
  }

  .select2-results__option--group {
    padding: 0;
  }

  .select2-results__option--disabled {
    color: grey;
  }

  .select2-results__option--highlighted.select2-results__option--selectable {
    background-color: $primary;
    color: $white;
  }

  .select2-results__group {
    cursor: default;
    display: block;
    padding: 6px;
  }

  &.select2-container--open .select2-dropdown {
    border-color: $primary;
  }
}
.select2-container--default .select2-results__option--highlighted[aria-selected] {
  color: #6259ca;
}
.select2-container--default .select2-results__option[aria-selected=true] {
  background-color: #f8f9ff !important;
}

.select2-sm .select2-container .select2-selection--single {
  height: calc(1.5rem + 4px) !important;
  padding-top: 0.391rem;
  padding-bottom: 0.391rem;
  font-size: 0.75rem;
}

.select2-sm .select2-container--default .select2-selection--single .select2-selection__rendered {
  line-height: 13px !important;
}

.select2-sm .select2-container--default .select2-selection--single .select2-selection__arrow {
  height: 1.85rem !important;
}

.select2-lg .select2-container .select2-selection--single {
  height: calc(2.6975rem + 2px) !important;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  font-size: 100%;
}

.select2-lg .select2-container--default .select2-selection--single .select2-selection__rendered {
  line-height: 28px !important;
}

.select2-lg .select2-container--default .select2-selection--single .select2-selection__arrow {
  height: 2.95rem !important;
}
.select2-search.select2-search--inline{
     display: none;
}