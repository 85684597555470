/*-- Chart --*/

.c3 {
  svg {
    font: 10px sans-serif;
    -webkit-tap-highlight-color: transparent;
    font-family: "Source Sans Pro", -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Arial, sans-serif;
  }

  path, line {
    fill: none;
    stroke: rgba(67, 87, 133, 0.09);
  }

  text {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    font-size: px2rem(12px);
  }
}

.c3-legend-item-tile, .c3-xgrid-focus, .c3-ygrid, .c3-event-rect, .c3-bars path {
  shape-rendering: crispEdges;
}

.c3-chart-arc {
  path {
    stroke: $white;
  }

  text {
    fill: $white;
    font-size: 13px;
  }
}

/*-- Axis --*/

/*-- Grid --*/

.c3-grid {
  line {
    stroke: rgba(67, 87, 133, 0.09);
  }

  text {
    fill: #8e9cad;
  }
}

.c3-xgrid, .c3-ygrid {
  stroke: #e6e6e6;
  stroke-dasharray: 2 4;
}

/*-- Text on Chart --*/

.c3-text {
  font-size: 12px;

  &.c3-empty {
    fill: #808080;
    font-size: 2em;
  }
}

/*-- Line --*/

.c3-line {
  stroke-width: 2px;
}

/*-- Point --*/

.c3-circle._expanded_ {
  stroke-width: 2px;
  stroke: white;
}

.c3-selected-circle {
  fill: white;
  stroke-width: 1.5px;
}

/*-- Bar --*/

.c3-bar {
  stroke-width: 0;

  &._expanded_ {
    fill-opacity: 1;
    fill-opacity: 0.75;
  }
}

/*-- Focus --*/

.c3-target {
  &.c3-focused {
    opacity: 1;

    path {
      &.c3-line, &.c3-step {
        stroke-width: 2px;
      }
    }
  }

  &.c3-defocused {
    opacity: 0.3 !important;
  }
}

/*-- Region --*/

.c3-region {
  fill: steelblue;
  fill-opacity: .1;
}

/*-- Brush --*/

.c3-brush .extent {
  fill-opacity: .1;
}

/*-- Select - Drag --*/

/*-- Legend --*/

.c3-legend-item text {
  fill: #545454;
  font-size: 14px;
}

.c3-legend-item-hidden {
  opacity: 0.15;
}

.c3-legend-background {
  fill: transparent;
  stroke: lightgray;
  stroke-width: 0;
}

/*-- Title --*/

.c3-title {
  font: 14px sans-serif;
}

/*-- Tooltip --*/

.c3-tooltip-container {
  z-index: 10;
}

.c3-tooltip {
  border-collapse: collapse;
  border-spacing: 0;
  empty-cells: show;
  font-size: 11px;
  line-height: 1;
  font-weight: 700;
  color: $white;
  border-radius: 3px;
  background: #212529;
  white-space: nowrap;

  th {
    padding: 6px 6px;
    text-align: left;
  }

  td {
    padding: 4px 6px;
    font-weight: 400;

    > span {
      display: inline-block;
      width: 8px;
      height: 8px;
      margin-right: 8px;
      border-radius: 50%;
      vertical-align: baseline;
    }

    &.value {
      text-align: right;
    }
  }
}

/*-- Area --*/

.c3-area {
  stroke-width: 0;
  opacity: 0.1;
}

.c3-target-filled .c3-area {
  opacity: 1 !important;
}

/*-- Arc --*/

.c3-chart-arcs-title {
  dominant-baseline: middle;
  font-size: 1.3em;
}

.c3-chart-arcs {
  .c3-chart-arcs-background {
    fill: #e0e0e0;
    stroke: none;
  }

  .c3-chart-arcs-gauge-unit {
    fill: $black;
    font-size: 16px;
  }

  .c3-chart-arcs-gauge-max, .c3-chart-arcs-gauge-min {
    fill: #777;
  }
}

.c3-chart-arc {
  .c3-gauge-value {
    fill: $black;

    /*  font-size: 28px !important;*/
  }

  &.c3-target {
    g path, &.c3-focused g path {
      opacity: 1;
    }
  }
}

.c3-axis {
  fill: #8e9cad;
}