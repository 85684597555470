$background: #f1f4fb;
$color:#263871;

/*Transparent variables*/
$transparent-theme:rgba(0, 0, 0, 0.2);
$transparent-theme2:rgba(0, 0, 0, 0.5);
$transparent-theme3:rgba(0, 0, 0, 0.1);
$transparent-body:var(--transparent-body);
$transparent-border:rgba(255, 255, 255, 0.2);

/*Dark variables*/
$dark-body:#151636;
$dark-theme:#25274a;
$dark-theme2:#262b5a;
$border:1px solid #e9ebfa;
$dark-color:#e9ebfa;


/*Color variables*/
$primary:var(--primary-bg-color);
$primary-01:var(--primary01);
$primary-02:var(--primary02);
$primary-03:var(--primary03);
$primary-06:var(--primary06);
$primary-08:var(--primary08);
$primary-09:var(--primary09);
$primary-hover:var(--primary-bg-hover);
$primary-border:var(--primary-bg-border);
$primary-transparent:var(--primary-transparentcolor);
$darkprimary-transparent:var(--darkprimary-transparentcolor);
$transparentprimary-transparent:var(--transparentprimary-transparentcolor);
$secondary:#fe7f00;
$success:#0dcd94;
$info:#128af9;
$warning:#e3b113;
$danger:#f7284a;
$blue:#083dc1;
$yellow:#ecb403;
$teal:#17d1dc;
$purple:#aa4cf2;
$pink:#ef4eb8;
$orange:#f34932;
$dark:#353a40;
$indigo:#6574cd;
$white:#fff;
$black:#000;
$muted:#9ba5ca;

/*gray variables*/
$gray-100:#f1f2f7;
$gray-200:#e5e7f1;
$gray-300:#d2d6e4;
$gray-400:#b7bdd2;
$gray-500:#a2aac7;
$gray-600:#8d96b7;
$gray-700:#7a85ad;
$gray-800:#53608c;
$gray-900:#424e79;

/*white variables*/
$white-1:rgba(255, 255, 255, 0.1);
$white-2:rgba(255, 255, 255, 0.2);
$white-3:rgba(255, 255, 255, 0.3);
$white-4 :rgba(255, 255, 255, 0.4);
$white-5 :rgba(255, 255, 255, 0.5);
$white-6 :rgba(255, 255, 255, 0.6);
$white-7 :rgba(255, 255, 255, 0.7);
$white-8 :rgba(255, 255, 255, 0.8);
$white-9 :rgba(255, 255, 255, 0.9);
$white-05:rgba(255, 255, 255, 0.05);
$white-08:rgba(255, 255, 255, 0.08);
$white-75:rgba(255, 255, 255, 0.075);

/*black variables*/
$black-1:rgba(0, 0, 0, 0.1);
$black-2:rgba(0, 0, 0, 0.2);
$black-3:rgba(0, 0, 0, 0.3);
$black-4:rgba(0, 0, 0, 0.4);
$black-5:rgba(0, 0, 0, 0.5);
$black-6:rgba(0, 0, 0, 0.6);
$black-7:rgba(0, 0, 0, 0.7);
$black-8:rgba(0, 0, 0, 0.8);
$black-9:rgba(0, 0, 0, 0.9);
$black-05:rgba(0, 0, 0, 0.05);


	

