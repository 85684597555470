

/* Ion.RangeSlider, Flat UI Skin
// css version 2.0.3
// Â© Denis Ineshin, 2014    https://github.com/IonDen
// ===================================================================================================================*/

/* =====================================================================================================================
// Skin details */

.irs {
  height: 40px;
}

.irs-with-grid {
  height: 60px;
}

.irs-line {
  height: 12px;
  top: 25px;
}

.irs-line-left {
  height: 12px;
  background-position: 0 -30px;
}

.irs-line-mid {
  height: 12px;
  background-position: 0 0;
}

.irs-line-right {
  height: 12px;
  background-position: 100% -30px;
}

.irs-bar {
  height: 12px;
  top: 25px;
  background-position: 0 -60px;
}

.irs-bar-edge {
  top: 25px;
  height: 12px;
  width: 9px;
  background-position: 0 -90px;
}

.irs-shadow {
  height: 3px;
  top: 34px;
  background: $black;
  opacity: 0.25;
}

.lt-ie9 .irs-shadow {
  filter: alpha(opacity = 25);
}

.irs-slider {
  width: 16px;
  height: 18px;
  top: 22px;
  background-position: 0 -120px;

  &.state_hover, &:hover {
    background-position: 0 -150px;
  }
}

.irs-min, .irs-max {
  color: #7f88a5;
  font-size: 10px;
  line-height: 1.333;
  text-shadow: none;
  top: 0;
  padding: 2px 6px;
  background: #eaeefa;
  -moz-border-radius: 4px;
  border-radius: 4px;
}

.irs-from, .irs-to, .irs-single {
  color: $white;
  font-size: 10px;
  line-height: 1.333;
  text-shadow: none;
  padding: 1px 5px;
  background: #f72d66;
  -moz-border-radius: 4px;
  border-radius: 4px;
}

.irs-from:after, .irs-to:after, .irs-single:after {
  position: absolute;
  display: block;
  content: "";
  bottom: -6px;
  left: 50%;
  width: 0;
  height: 0;
  margin-left: -3px;
  overflow: hidden;
  border: 3px solid transparent;
  border-top-color: #f72d66;
}

.irs-grid-pol {
  background: #e1e4e9;
}

.irs-grid-text {
  color: #7f88a5;
}

.irs-disabled {}

/*--- RANGE SLIDER ---*/

.irs-min, .irs-max {
  font-family: "Roboto", sans-serif;

  /* border-radius: 0; */
}

.irs-from, .irs-to, .irs-single {
  font-family: 'Roboto', sans-serif;
  background-color: $primary;
  border-radius: 0;
}

.irs-bar {
  background-image: none;
  background-color: $primary;
}

.irs-bar-edge {
  background-image: none;
  background-color: $primary;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.irs-line-left {
  background-image: none;
  background-color: #eaeefa;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.irs-line-mid {
  background-image: none;
  background-color: #eaeefa;
}

.irs-line-right {
  background-image: none;
  background-color: #eaeefa;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.irs-from::after, .irs-to::after, .irs-single::after {
  border-top-color: $primary;
}

.irs-slider {
  background-image: none;

  &:before {
    content: '';
    width: 2px;
    height: 18px;
    position: absolute;
    background-color: $primary;
    left: 50%;
    margin-left: -1px;
  }

  &:hover:before, &:focus:before {
    background-color: $primary;
  }
}

/* #################### COLOR VARIANT SKINS #################### */

.irs-primary {
  .irs-bar, .irs-bar-edge, .irs-slider::before, .irs-from, .irs-to, .irs-single {
    background-color: $primary;
  }

  .irs-from::after, .irs-to::after, .irs-single::after {
    border-top-color: $primary;
  }
}

.irs-success {
  .irs-bar, .irs-bar-edge, .irs-slider::before, .irs-from, .irs-to, .irs-single {
    background-color: #28c76f;
  }

  .irs-from::after, .irs-to::after, .irs-single::after {
    border-top-color: #28c76f;
  }
}

.irs-dark {
  .irs-bar, .irs-bar-edge, .irs-slider::before, .irs-from, .irs-to, .irs-single {
    background-color: #191621;
  }

  .irs-from::after, .irs-to::after, .irs-single::after {
    border-top-color: #191621;
  }
}

/* #################### MODERN SKIN #################### */

.irs-modern {
  .irs-line-left, .irs-line-mid, .irs-line-right {
    height: 6px;
    box-shadow: inset -2px 2px 2px 0 rgba(34, 34, 34, 0.1);
  }

  .irs-bar, .irs-bar-edge {
    height: 6px;
    box-shadow: inset 0 1px 1px 0 rgba(34, 34, 34, 0.1);
  }

  .irs-slider {
    top: 19px;
    background-color: $white;
    border: 1px solid $primary;
    border-radius: 0;

    &::before {
      width: 4px;
      height: 9px;
      background-color: $white;
      border-left: 1px solid $primary;
      border-right: 1px solid $primary;
      margin-left: -2px;
      top: 3px;
    }
  }

  &.irs-primary .irs-slider {
    border-color: $primary;

    &::before {
      border-color: $primary;
    }
  }

  &.irs-success .irs-slider {
    border-color: #28c76f;

    &::before {
      border-color: #28c76f;
    }
  }

  &.irs-dark .irs-slider {
    border-color: #14112d;

    &::before {
      border-color: #14112d;
    }
  }
}

/* #################### OUTLINE SKIN #################### */

.irs-outline {
  .irs-line {
    border: 1px solid $primary;
    height: 10px;
    border-radius: 5px;
  }

  .irs-line-left, .irs-line-mid, .irs-line-right {
    height: 10px;
    background-color: transparent;
  }

  .irs-bar {
    height: 10px;
  }

  .irs-bar-edge {
    height: 10px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }

  .irs-slider {
    top: 21px;
    width: 18px;
    background-color: $white;
    border: 1px solid $primary;
    border-radius: 100%;

    &::before {
      margin-left: -4px;
      top: 4px;
      width: 8px;
      height: 8px;
      border-radius: 100%;
    }

    &:hover::before, &:focus::before {
      background-color: $primary;
    }
  }

  &.irs-primary {
    .irs-line, .irs-slider {
      border-color: $primary;
    }

    .irs-line {
      &:hover::before, &:focus::before {
        background-color: $primary;
      }
    }

    .irs-slider {
      &:hover::before, &:focus::before {
        background-color: $primary;
      }
    }
  }

  &.irs-success {
    .irs-line, .irs-slider {
      border-color: #28c76f;
    }

    .irs-line {
      &:hover::before, &:focus::before {
        background-color: #339701;
      }
    }

    .irs-slider {
      &:hover::before, &:focus::before {
        background-color: #339701;
      }
    }
  }

  &.irs-dark {
    .irs-line, .irs-slider {
      border-color: #191621;
    }

    .irs-line {
      &:hover::before, &:focus::before {
        background-color: #313c53;
      }
    }

    .irs-slider {
      &:hover::before, &:focus::before {
        background-color: #313c53;
      }
    }
  }
}

/*--- RANGE SLIDER ---*/