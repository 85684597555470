
.cookie-popup {
  display: none;
  position: fixed;
  left: 0;
  z-index: 9999;
  background-color: #f3f4f7;
  color: #313e6a;
  line-height: initial;
  box-sizing: initial;
  border-radius: 13px;
  margin: 15px;
  right: 0;
}

.cookie-popup-inner {
  display: table;
  width: 100%;
  box-shadow: 0px 16px 18px rgba(6, 10, 48, 0.1);
  border-radius: 13px;
}

.cookie-popup-left {
  display: table-cell;
  vertical-align: middle;
  padding: 17px;
}

.cookie-popup-headline {
  font-size: 14px;

  /* color: #14112d; */
  font-weight: 700;
  text-transform: uppercase;
  line-height: 1;
  margin-bottom: 5px;
  letter-spacing: .2px;
}

.cookie-popup-sub-headline {
  font-size: 12px;
  line-height: 20px;
}

.cookie-popup-right {
  display: table-cell;
  vertical-align: middle;
  padding: 0px 15px 10px 15px;
  text-align: right;

  /* border-top: $border; */
}

.position-bottom .cookie-popup-right, .position-top .cookie-popup-right {
  padding: 10px 15px 10px 15px;
}

.cookie-popup-accept-cookies {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 13px;
  line-height: 1.5;
  border-radius: 3px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  &:hover {
    bottom: 1px;
    text-decoration: none;
    color: inherit;
  }

  &:active {
    bottom: 0;
    color: initial;
  }
}

.cookie-popup-learn-more {
  display: inline-block;
  font-weight: 400;
  color: #160248;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 13px;
  line-height: 1.5;
  border-radius: 3px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  margin-left: 5px;

  &:hover {
    opacity: 1;
    text-decoration: none;
  }

  &:active, &:focus {
    text-decoration: none;
  }
}

.cookie-popup-lower {
  padding: 15px;
  font-size: 12px;
  line-height: 20px;
}

/* Positions */

.cookie-popup {
  &.position-bottom {
    bottom: 0;
    box-shadow: 0px 16px 18px rgba(6, 10, 48, 0.1);
  }

  &.position-top {
    top: 0;
    box-shadow: 0px 16px 18px rgba(6, 10, 48, 0.1);
  }

  &.position-bottomright {
    width: 300px;
    right: 10px;
    bottom: 10px;
    left: inherit;
    box-shadow: 0px 16px 18px rgba(6, 10, 48, 0.1);
    border-radius: 13px;

    .cookie-popup-right {
      width: initial;
    }
  }

  &.position-bottomleft {
    width: 300px;
    left: 10px;
    bottom: 10px;
    right: inherit;
    box-shadow: 0px 16px 18px rgba(6, 10, 48, 0.1);
    border-radius: 13px;

    .cookie-popup-right {
      width: initial;
    }
  }

  &.position-topright {
    width: 320px;
    right: 10px;
    top: 10px;
    left: inherit;
    box-shadow: 0px 16px 18px rgba(6, 10, 48, 0.1);
    border-radius: 13px;

    .cookie-popup-right {
      width: initial;
    }
  }

  &.position-topleft {
    width: 300px;
    left: 10px;
    top: 10px;
    box-shadow: 0px 16px 18px rgba(6, 10, 48, 0.1);
    border-radius: 13px;
  }
}

.dark-mode .cookie-popup.position-topleft {
  box-shadow: none;
}

.cookie-popup {
  &.position-topleft {
    .cookie-popup-right {
      width: initial;
    }

    .cookie-popup-left, .cookie-popup-right {
      display: block;
      text-align: inherit;
    }
  }

  &.position-topright {
    .cookie-popup-left, .cookie-popup-right {
      display: block;
      text-align: inherit;
    }
  }

  &.position-bottomleft {
    .cookie-popup-left, .cookie-popup-right {
      display: block;
      text-align: inherit;
    }
  }

  &.position-bottomright {
    .cookie-popup-left, .cookie-popup-right {
      display: block;
      text-align: inherit;
    }
  }

  &.position-topleft .cookie-popup-headline, &.position-topright .cookie-popup-headline, &.position-bottomleft .cookie-popup-headline, &.position-bottomright .cookie-popup-headline {
    margin-bottom: 5px;
  }
}

/* Themes */

.theme-white {
  .cookie-popup {
    background-color: $white;
    color: #313e6a;
  }

  .cookie-popup-accept-cookies {
    background-color: $primary;
    color: $white;
  }

  .cookie-popup-learn-more {
    color: #313e6a;
    background: #f3f4f7;
  }

  .cookie-popup-inner {
    border-bottom: $border;
  }
}

.theme-light {
  .cookie-popup {
    background-color: #efeff5;
    color: #313e6a;
  }

  .cookie-popup-accept-cookies {
    background-color: $primary;
    color: $white;
  }

  .cookie-popup-learn-more {
    color: #313e6a;
    background: #f3f4f7;
  }

  .cookie-popup-inner {
    border-bottom: $border;
  }
}

.theme-dark {
  .cookie-popup {
    background-color: #23293a;
    color: $white;

    .cookie-popup-inner {
      box-shadow: none;
    }
  }

  .cookie-popup-accept-cookies {
    background-color: rgb(68, 84, 195);
    color: $white;
  }

  .cookie-popup-learn-more {
    color: $white;
    background: rgba(0, 0, 0, 0.31);
  }
}

.theme-primary {
  .cookie-popup {
    background-color: $primary;
    color: $white;
  }

  .cookie-popup-accept-cookies {
    background-color: rgba(255, 255, 255, 0.2);
    color: $white;
  }

  .cookie-popup-learn-more {
    color: $white;
    background: rgba(0, 0, 0, 0.2);
  }
}

.theme-info {
  .cookie-popup {
    background-color: #45aaf2;
    color: $white;
  }

  .cookie-popup-accept-cookies {
    background-color: rgba(255, 255, 255, 0.2);
    color: $white;
  }

  .cookie-popup-learn-more {
    color: $white;
    background: rgba(0, 0, 0, 0.2);
  }
}

.theme-danger {
  .cookie-popup {
    background-color: #dc0441;
    color: $white;
  }

  .cookie-popup-accept-cookies {
    background-color: rgba(255, 255, 255, 0.2);
    color: $white;
  }

  .cookie-popup-learn-more {
    color: $white;
    background: rgba(0, 0, 0, 0.2);
  }
}

.theme-success {
  .cookie-popup {
    background-color: #2dce89;
    color: $white;
  }

  .cookie-popup-accept-cookies {
    background-color: rgba(255, 255, 255, 0.2);
    color: $white;
  }

  .cookie-popup-learn-more {
    color: $white;
    background: rgba(0, 0, 0, 0.2);
  }
}

.theme-warning {
  .cookie-popup {
    background-color: $yellow;
    color: $white;
  }

  .cookie-popup-accept-cookies {
    background-color: rgba(255, 255, 255, 0.2);
    color: $white;
  }

  .cookie-popup-learn-more {
    color: $white;
    background: rgba(0, 0, 0, 0.2);
  }
}

@media (min-width: 600px) and (max-width: 680px) {
  .cookie-popup-left {
    width: 65%;
  }

  .cookie-popup-right {
    width: 35%;
  }
}

@media (max-width: 480px) {
  .cookie-popup-left {
    display: block;
    width: 100%;
  }

  .cookie-popup-right {
    display: block;
    width: 100%;
    text-align: left;
  }
}

@media (max-width: 767px) {
  .cookie-popup-learn-more {
    margin-top: 5px;
  }
}