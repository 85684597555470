.daterangepicker {
	position: absolute;
	color: inherit;
	background-color: #fff;
	border-radius: 4px;
	border: 1px solid #e9ebfa;
	width: 278px;
	max-width: none;
	padding: 0;
	top: 100px;
	left: 20px;
	z-index: 1;
	display: none;
	font-family: arial;
	font-size: 15px;
	line-height: 1em;
  
	&:after {
	  position: absolute;
	  display: inline-block;
	  content: "";
	}
  
	&:before {
	  position: absolute;
	  display: inline-block;
	  content: "";
	  top: -7px;
	  border-right: 7px solid transparent;
	  border-left: 7px solid transparent;
	  border-bottom: 7px solid #ccc;
	}
  
	&:after {
	  top: -6px;
	  border-right: 6px solid transparent;
	  border-bottom: 6px solid #fff;
	  border-left: 6px solid transparent;
	}
  
	&.opensleft {
	  &:before {
		right: 9px;
	  }
  
	  &:after {
		right: 10px;
	  }
	}
  
	&.openscenter {
	  &:after, &:before {
		left: 0;
		right: 0;
		width: 0;
		margin-left: auto;
		margin-right: auto;
	  }
	}
  
	&.opensright {
	  &:before {
		right: 9px;
	  }
  
	  &:after {
		right: 10px;
	  }
	}
  
	&.drop-up {
	  margin-top: -7px;
  
	  &:before {
		top: initial;
		bottom: -7px;
		border-bottom: initial;
		border-top: 7px solid #ccc;
	  }
  
	  &:after {
		top: initial;
		bottom: -6px;
		border-bottom: initial;
		border-top: 6px solid #fff;
	  }
	}
  
	&.single {
	  .calendar, .daterangepicker .ranges {
		float: none;
	  }
  
	  .drp-selected {
		display: none;
	  }
	}
  
	&.show-calendar {
	  .calendar, .drp-buttons {
		display: block;
	  }
	}
  
	&.auto-apply .drp-buttons {
	  display: none;
	}
  
	.calendar {
	  display: block;
	  max-width: 270px;
  
	  &.left {
		padding: 8px 0 8px 8px;
	  }
  
	  &.right {
		padding: 8px;
	  }
  
	  &.single .calendar-table {
		border: none;
	  }
	}
  
	.calendar-table {
	  .next span, .prev span {
		color: #fff;
		border: solid #000;
		border-width: 0 2px 2px 0;
		border-radius: 0;
		display: inline-block;
		padding: 3px;
	  }
  
	  .next span {
		transform: rotate(-45deg);
		-webkit-transform: rotate(-45deg);
	  }
  
	  .prev span {
		transform: rotate(135deg);
		-webkit-transform: rotate(135deg);
	  }
  
	  td, th {
		text-align: center;
		vertical-align: middle;
		min-width: 32px;
		width: 32px;
		height: 24px;
		line-height: 24px;
		font-size: 12px;
		border-radius: 4px;
		border: 1px solid transparent;
		white-space: nowrap;
		cursor: pointer;
	  }
  
	  border: 1px solid #fff;
	  border-radius: 4px;
	  background-color: #fff;
  
	  table {
		width: 100%;
		margin: 0;
		border-spacing: 0;
		border-collapse: collapse;
	  }
	}
  
	td.available:hover, th.available:hover {
	  background-color: #d6dbea;
	  border-color: transparent;
	  color: inherit;
	}
  
	td.week, th.week {
	  font-size: 80%;
	  color: #ccc;
	}
  
	td {
	  &.off {
		background-color: #fff;
		border-color: transparent;
		color: #b3bdca;
  
		&.end-date, &.in-range, &.start-date {
		  background-color: #fff;
		  border-color: transparent;
		  color: #b3bdca;
		}
	  }
  
	  &.in-range {
		background-color: #e5e9f2;
		border-color: transparent;
		color: #000;
		border-radius: 0;
	  }
  
	  &.start-date {
		border-radius: 4px 0 0 4px;
	  }
  
	  &.end-date {
		border-radius: 0 4px 4px 0;
	  }
  
	  &.start-date.end-date {
		border-radius: 4px;
	  }
  
	  &.active {
		background-color: #3366ff;
		border-color: transparent;
		color: #fff;
  
		&:hover {
		  background-color: #3366ff;
		  border-color: transparent;
		  color: #fff;
		}
	  }
	}
  
	th.month {
	  width: auto;
	}
  
	option.disabled, td.disabled {
	  color: #b3bdca;
	  cursor: not-allowed;
	  text-decoration: line-through;
	}
  }
  
  .hljs-link {}
  
  a {
	&:focus, &:hover {}
  }
  
  .daterangepicker {
	select {
	  &.monthselect, &.yearselect {
		font-size: 12px;
		padding: 1px;
		height: auto;
		margin: 0;
		cursor: default;
	  }
  
	  &.monthselect {
		margin-right: 2%;
		width: 56%;
	  }
  
	  &.yearselect {
		width: 40%;
	  }
  
	  &.ampmselect, &.hourselect, &.minuteselect, &.secondselect {
		width: 50px;
		margin: 0 auto;
		background: #e5e9f2;
		border: 1px solid #e5e9f2;
		padding: 2px;
		outline: 0;
		font-size: 12px;
	  }
	}
  
	.calendar-time {
	  text-align: center;
	  margin: 4px auto 0;
	  line-height: 30px;
	  position: relative;
  
	  select.disabled {
		color: #ccc;
		cursor: not-allowed;
	  }
	}
  
	.drp-buttons {
	  clear: both;
	  text-align: right;
	  padding: 8px;
	  border-top: 1px solid #e9ebfa;
	  display: none;
	  line-height: 12px;
	  vertical-align: middle;
	}
  
	.drp-selected {
	  display: inline-block;
	  font-size: 12px;
	  padding-right: 8px;
	}
  
	.drp-buttons .btn {
	  margin-left: 8px;
	  font-size: 12px;
	  font-weight: 700;
	  padding: 4px 8px;
	}
  
	&.show-ranges .calendar.left {
	  border-left: 1px solid #e9ebfa;
	}
  
	.ranges {
	  float: none;
	  text-align: left;
	  margin: 0;
	}
  
	&.show-calendar .ranges {
	  padding-top: 0px;
	}
  
	.ranges {
	  ul {
		list-style: none;
		margin: 0 auto;
		padding: 0;
		width: 100%;
	  }
  
	  li {
		font-size: 12px;
		padding: 8px 12px;
		cursor: pointer;
  
		&:hover {
		  background-color: #e5e9f2;
		}
  
		&.active {
		  background-color: #3366ff;
		  color: #fff;
		}
	  }
	}
  }
  
  @media (min-width: 564px) {
	.daterangepicker {
	  &.ltr .calendar.right .calendar-table, &.rtl .calendar.left .calendar-table {
		border-left: none;
		border-top-left-radius: 0;
		border-bottom-left-radius: 0;
	  }
  
	  &.ltr .calendar.left .calendar-table, &.rtl .calendar.right .calendar-table {
		border-right: none;
		border-top-right-radius: 0;
		border-bottom-right-radius: 0;
	  }
  
	//   width: auto;
	//   left: auto !important;
	//   right: 20px !important;
  
	  .ranges ul {
		width: auto;
	  }
  
	  &.single {
		.ranges ul {
		  width: 100%;
		}
  
		.calendar.left {
		  clear: none;
		}
  
		&.ltr {
		  .calendar, .ranges {
			float: left;
		  }
		}
  
		&.rtl {
		  .calendar, .ranges {
			float: right;
		  }
		}
	  }
  
	  &.ltr {
		direction: ltr;
		text-align: left;
  
		.calendar {
		  &.left {
			clear: left;
			margin-right: 0;
  
			.calendar-table {
			  padding-right: 8px;
			}
		  }
  
		  &.right {
			margin-left: 0;
		  }
  
		  float: left;
		}
  
		.ranges {
		  float: left;
		}
	  }
  
	  &.rtl {
		direction: rtl;
		text-align: right;
  
		.calendar {
		  &.left {
			clear: right;
			margin-left: 0;
  
			.calendar-table {
			  padding-left: 12px;
			}
		  }
  
		  &.right {
			margin-right: 0;
		  }
  
		  text-align: right;
		  float: right;
		}
  
		.ranges {
		  text-align: right;
		  float: right;
		}
	  }
	}
  }
  
  @media (min-width: 730px) {
	.daterangepicker {
	  .ranges {
		width: auto;
	  }
  
	  &.ltr .ranges {
		float: left;
		border-right: 1px solid #e9ebfa;
	  }
  
	  &.rtl .ranges {
		float: right;
	  }
  
	  .calendar.left {
		clear: none !important;
	  }
	}
  }
  
  .range_inputs {
	padding: 20px;
	border-top: 1px solid #e9ebfa;
  }
  
  .daterangepicker_input {
	position: relative;
  
	i {
	  position: absolute;
	  top: 12px;
	  right: 12px;
	}
  }